<template>
  <b-modal
    id="modal-programarCita"
    size="custom-lg-max"
    title="Reservar Cita"
    hide-footer
    @hidden="onHidden"
  >
    <div class="row">
      <div class="col-sm-5 pr-0">
        <div class="form-group">
          <label>Paciente:</label>
          <multiselect
            v-model="pacienteSelected"
            :options="getPacientes"
            :loading="isLoadingComboPacientes"
            @open="obtenerListaPacientes('')"
            placeholder=""
            track-by="id"
            label="full_name"
            select-label=""
            deselect-label="X"
          >
            <span slot="caret" v-if="!(pacienteSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
            <template slot="singleLabel" slot-scope="props">
              <small>{{props.option.full_name}}</small>
            </template>
            <template slot="option" slot-scope="props">
              <small>{{props.option.full_name}}</small>
            </template>
          </multiselect>
        </div>
      </div>
       <div class="col-sm-1">
        <div class="form-group">
          <label style="user-select: none"><br></label>
          <button
            class="btn btn-success pr-3"
            @click="openModalPaciente"
          >
            +
          </button>
          <ModalPacienteNuevo/>
        </div>
      </div>
      <div class="col-sm-6 text-right" v-if="pacienteSelected">
        <div class="form-group">
          <div class="row">
            <label style="user-select: none"><br></label>
          </div>
          <button
      :class="['btn', isClinic ? 'btnOrange' : 'btn-secondary', 'mr-2']"
      @click="selectType()"
    >
      Clinica
    </button>
    <button
      :class="['btn', !isClinic ? 'btnOrange' : 'btn-secondary', 'mr-2']"
      @click="selectType()"
    >
      Medico
    </button>
      </div>
      </div>
    </div>
    <div class="row mx-0 mb-2" v-if="pacienteSelected">
      <div :class="{'bg-skyblue': !isClinic, 'bg-row': isClinic}" class="col-sm-12 py-2">
          <button
          :class="{'btn btn-success': buttonConsultaActive,
            'btn btn-secondary': !buttonConsultaActive}"
          @click="isActiveButton('consulta')"
          class="btn px-4 mr-3">
          Consulta
        </button>
        <button
          :class="{'btn btn-success': buttonReConsultaActive,
            'btn btn-secondary': !buttonReConsultaActive}"
          @click="isActiveButton('reconsulta')"
          style="display: none;"
          disabled
          class="btn px-4 mr-3">
          Re Consulta
        </button>
        <button
          :class="{'btn btn-success': buttonServicioActive,
            'btn btn-secondary': !buttonServicioActive}"
          @click="isActiveButton('servicio')"
          class="btn px-4 mr-3">
          Servicios
        </button>
        <button
          :class="{'btn btn-success': buttonTratamientoActive,
            'btn btn-secondary': !buttonTratamientoActive}"
          @click="isActiveButton('tratamiento')"
          style="display: none"
          disabled
          class="btn px-4">
          Tratamiento
        </button>
      </div>
    </div>
    <div class="row mb-2" v-if="(buttonServicioActive || buttonConsultaActive) && pacienteSelected">
      <div class="col-sm-7 pr-1">
        <div class="form-group">
          <div class="row">
            <div class="col-sm-6">
              <label>Servicios:</label>
            </div>
          </div>
          <multiselect ref="comboServices"
              v-model="serviciosSelected"
              :options="listaServiciosInteligente"
              :loading="loaderComboServicio"
              @select="selectServices"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
                <span slot="caret" v-if="!(serviciosSelected)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="singleLabel" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
                <template slot="option" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
            </multiselect>
        </div>
      </div>
      <div class="col-sm-4 px-1">
        <div class="row">
          <div class="col-sm-3 pr-1 mt-4">
            <button
              :class="['btn', isPaid
                ? 'btn-success' : 'btn-secondary', 'btn-block', 'mt-2 py-1', 'px-1']"
              @click="togglePaymentStatus"
            >
              <small>Paga</small>
            </button>
          </div>
          <div class="col-sm-3 pl-0 mt-4">
            <button
              :class="['btn', !isPaid ?
                'btn-success' : 'btn-secondary', 'btn-block', 'mt-2 py-1', 'px-1']"
              @click="togglePaymentStatus"
            >
              <small>No Paga</small>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2" v-if="this.serviciosSelected ||
      (!this.isClinic && this.pacienteSelected
        && (this.buttonConsultaActive || this.buttonServicioActive))">
      <!-- inicio foreach -->
      <div class="col-sm-12">
        <div class="form-group row"
          v-for="(medico, index) in medicoSelecteds"
          v-bind:item="medico"
          v-bind:index="index"
          v-bind:key="index">
          <div class="col-sm-6">
            <div class="col-sm-12 pr-0">
                <div class="row">
                  <div class="col-sm-4">
                    <label>Profesional:</label>
                  </div>
                  <div class="col text-right">
                    <label :class="textTitleCard(medico.tipo_turno_medico_id)">({{ titleCard }})</label>
                  </div>
                </div>
              <div class="row mb-2">
                <div class="col-sm-1" v-if="medicoSelecteds.length > 1">
                  <i class="nav-icon i-Close-Window font-weight-bold text-danger"
                    @click="elimiarItemMedicoSelected(index)"
                    style="cursor: pointer"></i>
                </div>
                <div class="col-sm-8">
                  <multiselect v-model="medico.medico_selected"
                  :options="getListaMedicos()"
                  :loading="loadingMedico"
                  placeholder=""
                  label="nombre"
                  track-by="id"
                  select-label=""
                  deselect-label="X"
                  :disabled="medicoSelecteds.length > 1"
                  @input="getTurnoMedicoSelected(medico, index)">
                  <span slot="caret" v-if="medico.medico_selected !== null"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                  <template slot="singleLabel" slot-scope="props">
                  <small>{{ props.option.nombre }}</small>
                  </template>
                  <template slot="option" slot-scope="props">
                  <small>{{ props.option.nombre }}</small>
                  </template>
                  </multiselect>
                </div>
                <div class="col-sm-2 mr-2" v-if="medico.medico_selected">
                  <button v-if="medico.state_turno1"
                    @click="selectTurno1(index)"
                    class="btn mr-3"
                    :class="medico.turno === 1? 'btn-success' : 'btn-secondary'"
                  >Turno 1</button>
                  <button v-if="medico.state_turno2"
                    @click="selectTurno2(index)"
                    class="btn"
                    :class="medico.turno === 2? 'btn-success' : 'btn-secondary'"
                  >Turno 2</button>
                </div>
              </div>
              <div class="row" v-if="buttonServicioActive">
                <button class="btn btn-success" type="button"
                v-if="index === maxLengthMedico && medico.medico_selected"
                  @click="addMedicoSelecteds">+</button>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <template v-if="medico.medico_selected">
                <div class="col-sm-4" >
                  <div class="form-group">
                    <label>Fecha:</label>
                    <date-picker
                      id="inputFecha"
                      class="w-100"
                      v-model="medico.fecha_turno"
                      @input="onInputFecha(index)"
                      :disabled-date="disabledBeforeToday"
                      lang="es"
                      format="DD/MM/YYYY"
                      type="date"
                      value-type="date"
                    ></date-picker>
                  </div>
                </div>
                <div class="col-sm-3 mt-1 pr-0 pl-1"  v-if="medico.tipo_turno_medico_id === 1">
                  <div class="form-group">
                    <span class="d-inline-block"></span>
                    <input
                    :value="horariosDisponibles(index)"
                    @click="medico.is_seleccion_manual=!medico.is_seleccion_manual"
                    type="text"
                    class="form-control text-center"
                    style="cursor:pointer;"
                    readonly="true"
                  >
                  </div>
                </div>
                <div class="col-sm-5 mt-4 text-right" v-if="medico.tipo_turno_medico_id !== 3">
                  <div
                    v-if="medico.is_seleccion_manual || medico.tipo_turno_medico_id === 2"
                  >
                    <input
                      v-model="medico.horario_manual_value"
                      type="time"
                      class="form-control"
                    >
                  </div>
                  <div
                    v-if="!medico.is_seleccion_manual && medico.tipo_turno_medico_id === 1"
                  >
                    <multiselect
                      v-model="medico.horario_selected"
                      :options="medico.lista_horarios"
                      placeholder=""
                      selectedLabel=""
                      label="hora"
                      track-by="ticket"
                      select-label=""
                      deselect-label="X"
                    >
                      <span slot="caret" v-if="!(medico.horario_selected===null)"></span>
                      <span slot="noResult">Sin Resultados</span>
                      <span slot="noOptions">No trabaja</span>
                      <template slot="singleLabel" slot-scope="props">
                        <small>{{props.option.ticket}} - {{props.option.hora}}</small>
                      </template>
                      <template slot="option" slot-scope="props">
                        <small
                          v-if="formatOptionLabel(props.option.hora, index)"
                          class="yellow-border"> {{ props.option.ticket }} - {{ props.option.hora }}</small>
                        <small v-else>{{ props.option.ticket }} - {{ props.option.hora }}</small>
                      </template>
                    </multiselect>
                  </div>
                </div>

                <div class="col-sm-4 mt-4 pr-1" v-if="medico.tipo_turno_medico_id === 3">
                  <button
                    :class="{ 'btn-success': medico.medicina_general_selected,
                      'btn-secondary': !medico.medicina_general_selected }"
                    class="btn btn-block"
                    @click="toggleMedicinaGeneral(index)">Medicina General</button>
                </div>
                <div class="col-sm-4 mt-4 pl-1" v-if="medico.tipo_turno_medico_id === 3">
                  <button
                    :class="{ 'btn-success': medico.emergencia_selected, 'btn-secondary': !medico.emergencia_selected }"
                    class="btn btn-block"
                    @click="toggleEmergencia(index)">Emergencia</button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- fin foreach -->
    </div>
    <div class="row mt-3" v-if="pacienteSelected">
      <div class="col-sm-12 text-right">
        <button
          class="btn btn-secondary mr-2"
          @click="hideModal"
        >
          Cancelar
        </button>
        <button
          class="btn btnOrange mr-2"
          @click="guardarModal"
        >
          Guardar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import { mapGetters, mapActions, mapState } from 'vuex';
import ModalPacienteNuevo from '../../facturacion/components/TheNuevoPacienteModal.vue';

export default {
  name: 'ModalProgramarCita',
  components: {
    Multiselect,
    DatePicker,
    ModalPacienteNuevo,
  },
  props: {
    getMedicos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      listaPacientes: [{ id: 0, full_name: 'prueba' }],
      fecha: null,
      listaMedicos: [{ id: 0, nombre: 'prueba' }],
      servicioSelected: null,
      buttonConsultaActive: false,
      buttonReConsultaActive: false,
      buttonServicioActive: false,
      buttonTratamientoActive: false,
      loaderComboServicio: false,
      loadingMedico: false,
      isDisabledEnfermeria: false,
      titleCard: 'Programar Atención',
      hasMedicTurnos: false,
      isPaid: false,
      isClinic: true,
      dataGuardar: [],
      serviciosSelected: null,
      pacienteSelected: null,
      medicoSelecteds: [
        {
          tipo: null,
          tipo_servicio_id: null,
          medico_id: null,
          medico_tercero_id: null,
          paga: false,
          numero_ticket: null,
          turno_medico_id: null,
          fecha_turno: null,
          hora_turno: null,
          venta_detalle_turno_id: 0,
          is_turno_manual: false,
          tipo_atencion_id: 0,
          medico_selected: null,
          tipo_turno_medico_id: null,
          state_turno1: false,
          state_turno2: false,
          turno: 1,
          horario_selected: null,
          numeroTicketFromEdit: null,
          horaTurnoFromEdit: null,
          lista_horarios: [],
          is_seleccion_manual: false,
          horario_manual_value: '',
          medicina_general_selected: false,
          emergencia_selected: false,
        },
      ],
    };
  },
  async created() {
    this.getServiciosApi();
  },
  methods: {
    hideModal() {
      this.resetModalData();
      this.$bvModal.hide('modal-programarCita');
    },
    openModalPaciente() {
      this.$bvModal.show('modal-nuevoPaciente');
    },
    togglePaymentStatus() {
      this.isPaid = !this.isPaid;
    },
    selectType() {
      this.isClinic = !this.isClinic;
      this.serviciosSelected = null;
      this.resetMedicoSelecteds();
    },
    isActiveButton(buttonType) {
      this.buttonConsultaActive = false;
      this.buttonReConsultaActive = false;
      this.buttonServicioActive = false;
      this.buttonTratamientoActive = false;
      // limpiar el array de medicos cuando no es servicios(volver a uno)
      if (buttonType === 'consulta') {
        this.buttonConsultaActive = !this.buttonConsultaActive;
      } else if (buttonType === 'reconsulta') {
        this.buttonReConsultaActive = !this.buttonReConsultaActive;
      } else if (buttonType === 'servicio') {
        this.buttonServicioActive = !this.buttonServicioActive;
      } else if (buttonType === 'tratamiento') {
        this.buttonTratamientoActive = !this.buttonTratamientoActive;
      }
    },
    getListaMedicos() {
      if (this.isClinic) {
        return this.getMedicosTratantes;
      }
      return this.getMedicos;
    },
    async getTurnoMedicoStatus(medicoId, index) {
      try {
        const REQUEST = {
          medico_id: medicoId,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/turno/configuracion', {
          params: REQUEST,
        });
        const TURNOS_MEDICO = RESPONSE.data.data.turno_medico;
        if (TURNOS_MEDICO.length > 0) {
          const T1 = TURNOS_MEDICO.find((el) => el.numero_turno === 1) ?? null;
          const T2 = TURNOS_MEDICO.find((el) => el.numero_turno === 2) ?? null;
          if (T1 !== null) {
            this.medicoSelecteds[index].state_turno1 = true;
          } else {
            this.medicoSelecteds[index].state_turno1 = false;
          }
          if (T2 !== null) {
            this.medicoSelecteds[index].state_turno2 = true;
          } else {
            this.medicoSelecteds[index].state_turno2 = false;
          }
          if (T1 !== null && T2 === null) {
            this.medicoSelecteds[index].turno = 1;
          }
          if (T1 === null && T2 !== null) {
            this.medicoSelecteds[index].turno = 2;
          }
        } else {
          this.hasMedicTurnos = false;
          this.medicoSelecteds[index].state_turno1 = false;
          this.medicoSelecteds[index].state_turno2 = false;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    toggleMedicinaGeneral(index) {
      if (this.medicoSelecteds[index].tipo_turno_medico_id === 3) {
        this.medicoSelecteds[index].medicina_general_selected = true;
        this.medicoSelecteds[index].emergencia_selected = false;
        this.medicoSelecteds[index].tipo_atencion_id = 1;
      }
    },
    toggleEmergencia(index) {
      if (this.medicoSelecteds[index].tipo_turno_medico_id === 3) {
        this.medicoSelecteds[index].emergencia_selected = true;
        this.medicoSelecteds[index].medicina_general_selected = false;
        this.medicoSelecteds[index].tipo_atencion_id = 2;
      }
    },
    sendData() {
      let fechaTurno = null;
      let horaTurno = null;
      let numeroTicket = null;
      let validate = true;
      this.medicoSelecteds.forEach((el, index) => {
        if (el.fecha_turno !== null) {
          fechaTurno = moment(el.fecha_turno).format('YYYY-MM-DD');
        }
        if (el.tipo_turno_medico_id === 1) {
          if (el.horario_selected === null || el.is_seleccion_manual) {
            horaTurno = el.horario_manual_value;
          } else {
            horaTurno = el.horario_selected?.hora ?? null;
            numeroTicket = el.horario_selected?.ticket ?? null;
          }
        }
        if (el.tipo_turno_medico_id === 2) {
          horaTurno = el.horario_manual_value;
        }
        const MEDICO_ID = el.medico_selected
          ? el.medico_selected.id : null;

        let medicoTerceroId = null;
        if (!this.isClinic) {
          medicoTerceroId = el.medico_selected ? el.medico_selected.id : null;
        }

        if (!horaTurno) {
          util.showNotify(`Debe Seleccionar una hora para la fila ${index + 1}`, 'warn');
          validate = false;
        }
        const DATA = {
          paciente_id: this.pacienteSelected ? this.pacienteSelected.id : null,
          tipo: this.buttonConsultaActive ? 'consulta' : 'servicio',
          tipo_servicios_id: this.serviciosSelected ? this.serviciosSelected.id : null,
          medico_id: MEDICO_ID,
          medico_tercero_id: medicoTerceroId,
          paga: this.isPaid,
          venta_detalle_turno_id: 0,
          numero_ticket: numeroTicket,
          turno_medico_id: el.turno_medico_id,
          fecha_turno: fechaTurno,
          hora_turno: horaTurno === '' ? null : horaTurno,

          is_turno_manual: el.is_seleccion_manual,
          medicina_general_selected: el.medicina_general_selected,
          emergencia_selected: el.emergencia_selected,
          tipo_atencion_id: el.tipo_atencion_id,
        };
        this.dataGuardar.push(DATA);
      });
      return validate;
    },
    async guardarModal() {
      if (!this.serviciosSelected) {
        util.showNotify('Debe Seleccionar un Servicio', 'warn');
        return;
      }
      if (this.medicoSelecteds[0].medico_selected === null) {
        util.showNotify('Debe Seleccionar un Profesional', 'warn');
        return;
      }
      try {
        if (this.sendData()) {
          const data = {
            reservas: this.dataGuardar,
          };
          const RESPONSE = await axios.post('clinic/consultation/patient/reserva/store', data);
          util.showNotify(RESPONSE.data.message, 'success');
          this.$emit('cita-agregada');
          this.$bvModal.hide('modal-programarCita');
          this.resetModalData();
        }
      } catch (error) {
        this.dataGuardar = [];
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
      }
    },
    onHidden() {
      this.resetModalData();
    },
    resetModalData() {
      this.pacienteSelected = null;
      this.serviciosSelected = null;
      this.resetMedicoSelecteds();
      this.dataGuardar = [];
      // Restablece otros datos del modal según sea necesario
    },
    textTitleCard(idTurno) {
      if (idTurno === 0) {
        return 'text-danger'; // Rojo
      } if (idTurno === 1) {
        return 'text-success'; // Verde
      } if (idTurno === 3) {
        return 'text-warning'; // Amarillo
      }
      return '';
    },
    formatTime(input) {
      if (input.includes(' - ')) {
        const parts = input.split(' - ');
        const timePart = parts[0];
        const formattedTime = moment(timePart, 'HH:mm:ss').format('HH:mm:ss');
        if (parts.length > 1) {
          return `${formattedTime} - ${parts.slice(1).join(' - ')}`;
        }
        return formattedTime;
      }
      return moment(input, 'HH:mm:ss').format('HH:mm:ss');
    },
    formatOptionLabel(option, index) {
      if (this.medicoSelecteds[index]) {
        if (option.includes(' - ')) {
          const parts = option.split(' - ');
          const timePart = parts[0];
          if (moment(timePart, 'HH:mm:ss').format('HH:mm:ss') === this.medicoSelecteds[index].horario_selected?.hora) {
            return true;
          }
        } else if (moment(option, 'HH:mm:ss').format('HH:mm:ss') === this.medicoSelecteds[index].horario_selected?.hora) {
          return true;
        }
      }
      return false;
    },
    cargarListaHorarios(lista, index) {
      const FECHA_SELECCIONADA = moment(this.medicoSelecteds[index].fecha_turno).format('YYYY-MM-DD');
      const FECHA_ACTUAL = moment(new Date()).format('YYYY-MM-DD');
      const HORA_ACTUAL = moment(new Date()).format('HH:mm');
      const TEMP_LIST = [];
      lista.forEach((el) => {
        let estado = false;
        let { hora } = el;
        if (el.hora === 'NO Disponible') {
          estado = true;
        } else if (FECHA_SELECCIONADA < FECHA_ACTUAL) {
          estado = true;
        } else if (HORA_ACTUAL > moment(el.hora, 'HH:mm:ss').format('HH:mm:ss') && FECHA_SELECCIONADA === FECHA_ACTUAL) {
          estado = true;
        } else if (this.medicoSelecteds[index].horario_selected != null) {
          if (moment(el.hora, 'HH:mm:ss').format('HH:mm:ss') === this.medicoSelecteds[index].horario_selected?.hora) {
            estado = true;
          }
        }
        if (this.medicoSelecteds[index].numeroTicketFromEdit !== null && this.medicoSelecteds[index].horaTurnoFromEdit !== null) {
          if (el.ticket === this.medicoSelecteds[index].numeroTicketFromEdit) {
            hora = this.formatTime(this.medicoSelecteds[index].horaTurnoFromEdit);
          }
        }
        if (hora) {
          hora = this.formatTime(hora);
        } else {
          hora = '';
        }
        if (hora.includes(' - ')) {
          estado = true;
        }
        const ITEM = {
          $isDisabled: estado,
          ticket: el.ticket,
          hora,
        };
        TEMP_LIST.push(ITEM);
      });
      this.medicoSelecteds[index].lista_horarios = TEMP_LIST;
    },
    async getDataModal(index) {
      if (this.medicoSelecteds[index].medico_selected === null
        || this.medicoSelecteds[index].fecha_turno === null) {
        return;
      }
      try {
        const REQUEST = {
          medico_id: this.medicoSelecteds[index].medico_selected.id,
          fecha: moment(this.medicoSelecteds[index].fecha_turno).format('YYYY-MM-DD'),
          turno: this.medicoSelecteds[index].turno,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/turno/', {
          params: REQUEST,
        });
        // aqui los valores deben de de estar dentro del array medico
        this.medicoSelecteds[index].turno_medico_id = RESPONSE.data.data.turno_medico?.id ?? null;

        const TURNOS = RESPONSE.data.data.turno_medico?.turnos ?? [];
        this.titleCard = RESPONSE.data.data.tipo_turno?.tipo_turno ?? 'Programar Atención';

        this.medicoSelecteds[index].tipo_turno_medico_id = RESPONSE.data.data.tipo_turno?.tipo_turno_medico_id ?? 0;
        this.cargarListaHorarios(TURNOS, index);
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
      }
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    async onInputFecha(index) {
      this.medicoSelecteds[index].horario_selected = null;
      this.medicoSelecteds[index].is_seleccion_manual = false;
      this.medicoSelecteds[index].lista_horarios = [];
      await this.getDataModal(index);
    },
    async selectTurno1(index) {
      this.medicoSelecteds[index].turno = 1;
      this.medicoSelecteds[index].horario_selected = null;
      await this.getDataModal(index);
    },
    async selectTurno2(index) {
      this.medicoSelecteds[index].turno = 2;
      this.medicoSelecteds[index].horario_selected = null;
      await this.getDataModal(index);
    },
    setHorarioActual(index) {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      this.medicoSelecteds[index].horario_manual_value = `${hours}:${minutes}:${seconds}`;
    },
    resetMedicoSelecteds() {
      this.medicoSelecteds = [
        {
          tipo: null,
          tipo_servicio_id: null,
          medico_id: null,
          medico_tercero_id: null,
          paga: false,
          numero_ticket: null,
          turno_medico_id: null,
          fecha_turno: null,
          hora_turno: null,
          venta_detalle_turno_id: 0,
          is_turno_manual: false,
          tipo_atencion_id: 0,
          medico_selected: null,
          tipo_turno_medico_id: null,
          state_turno1: false,
          state_turno2: false,
          turno: 1,
          horario_selected: null,
          numeroTicketFromEdit: null,
          horaTurnoFromEdit: null,
          lista_horarios: [],
          is_seleccion_manual: false,
          horario_manual_value: '',
          medicina_general_selected: false,
          emergencia_selected: false,
        },
      ];
    },
    async selectServices(item) {
      this.resetMedicoSelecteds();
      if (this.isClinic) {
        await this.getTratanteByServicioId(item.id);
      }
    },
    ...mapActions('formFactura', [
      'obtenerListaPacientes',
      'getTratanteByServicioId',
    ]),
    ...mapActions('addServiceModal', [
      'getServiciosApi',
    ]),
    async getTurnoMedicoSelected(item, index) {
      this.medicoSelecteds[index].is_seleccion_manual = false;
      this.medicoSelecteds[index].lista_horarios = [];
      this.medicoSelecteds[index].horario_selected = null;
      this.medicoSelecteds[index].turno_medico_id = null;
      this.medicoSelecteds[index].fecha_turno = new Date();
      this.medicoSelecteds[index].turno = 1;
      if (item.medico_selected != null) {
        const MEDICO_ID = item.medico_selected.id;
        await this.getTurnoMedicoStatus(MEDICO_ID, index);
        this.getDataModal(index);
      } else {
        this.hasMedicTurnos = false;
      }
    },
    horariosDisponibles(index) {
      if (this.medicoSelecteds[index].is_seleccion_manual) {
        this.setHorarioActual(index);
        return 'Abierto';
      }
      const LISTA_DISPONIBLES = [];
      this.medicoSelecteds[index].lista_horarios.forEach((el) => {
        if (el.$isDisabled === false) {
          LISTA_DISPONIBLES.push(el);
        }
      });
      return `Disponible: ${LISTA_DISPONIBLES.length}`;
    },
    addMedicoSelecteds() {
      if (this.maxLengthMedico === 7) {
        util.showNotify('Limite de medico no permitido', 'warn');
        return;
      }
      const ultimoItem = this.medicoSelecteds[this.medicoSelecteds.length - 1];
      const OBJ_MEDICO = JSON.parse(JSON.stringify(ultimoItem));
      OBJ_MEDICO.fecha_turno = new Date(OBJ_MEDICO.fecha_turno);
      OBJ_MEDICO.horario_selected = null;
      this.medicoSelecteds.push(OBJ_MEDICO);
    },
    elimiarItemMedicoSelected(index) {
      this.medicoSelecteds.splice(index, 1);
    },
  },
  computed: {
    listaServiciosInteligente() {
      return this.getServicios;
    },
    ...mapGetters('formFactura', [
      'getPacientes',
      'getMedicosTratantes', // (llegara en  otra api)
    ]),
    ...mapGetters('addServiceModal', [
      'getServicios',
    ]),
    ...mapState('formFactura', [
      'isLoadingComboPacientes',
    ]),
    maxLengthMedico() {
      return this.medicoSelecteds.length - 1;
    },
  },
  watch: {
    buttonServicioActive(new1) {
      if (!new1) {
        this.resetMedicoSelecteds();
      }
    },
  },
};
</script>

<style scoped>
.bg-skyblue
{
  background: #61c6f2 !important;
}
.bg-row {
  background-color: #eee;
}
.btnOrange {
  color: white;
  background-color: #F4B375;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
